import {http_client} from "@/plugins/http_client";

const getTiposDocumentos = async(body) => http_client('/api/v1/documento/tipos/',{},'get')
const getDocumentoPersona = async (param) => await http_client('/api/v1/documento/persona',param,'get')
const agregarDocumentoPersona = async (body) => await http_client('/api/v1/documento/persona',body,'post')
const eliminarDocumentoPersona = async (id_documento_persona) => await http_client(`/api/v1/documento/persona/${id_documento_persona}`,{},'delete')
const actualizarFotografiaDoc = async (body) => await http_client('/api/v1/documento/persona/actualizar-doc/'+body.id,body.archivo,'put')
export default{
    getTiposDocumentos,
    getDocumentoPersona,
    agregarDocumentoPersona,
    eliminarDocumentoPersona,
    actualizarFotografiaDoc
}
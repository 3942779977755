import {http_client} from "@/plugins/http_client";
import { async } from "regenerator-runtime";

const getFavoritos = async (parameter,body) => await http_client('/api/v1/favoritos/'+parameter, {}, 'get');
const deleteFavorito = async(parameter, body) => await http_client('/api/v1/favoritos/'+parameter, {}, 'delete')
const postFavorito = async (body) => await http_client('/api/v1/favoritos', body, 'post')
const putFavorito = async(body) => await http_client('api/v1/favoritos/alternar-favorito', body, 'put')
export default {
    getFavoritos,
    deleteFavorito,
    postFavorito,
    putFavorito
}



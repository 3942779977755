import { http_client } from "@/plugins/http_client"

const getCapacidadPersona = async (param) => await http_client('/api/v1/capacidad/persona-capacidad',param,'get')
const postCapacidadPersona = async (body) => await http_client('/api/v1/capacidad/persona-capacidad',body,'post')
const deleteCapacidadPersona = async(id_persona_capacidad) => await http_client(`/api/v1/capacidad/persona-capacidad/${id_persona_capacidad}`,{},'delete')

//Otros aspectos
const getOtrosAspectos = async (param) => await http_client('/api/v1/otro-aspecto',param,'get')
const postOtroAspecto = async (body) => await http_client('/api/v1/otro-aspecto',body,'post')
const deleteOtroAspecto = async (id_otros_aspectos) => await http_client(`/api/v1/otro-aspecto/${id_otros_aspectos}`,{},'delete')

//Area de interes
const getAreaInteres = async (param) => await http_client('/api/v1/area-interes',param,'get')
const postAreaInteres = async (body) => await http_client('/api/v1/area-interes',body,'post')
const deleteAreaInteres = async (id_area_interes_persona) => await http_client(`/api/v1/area-interes/${id_area_interes_persona}`,{},'delete')

export default{
    getCapacidadPersona,
    postCapacidadPersona,
    deleteCapacidadPersona,
    getOtrosAspectos,
    postOtroAspecto,
    deleteOtroAspecto,
    getAreaInteres,
    postAreaInteres,
    deleteAreaInteres,
}
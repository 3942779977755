import {http_client} from "@/plugins/http_client";
import { async } from "regenerator-runtime";

const getPersonaRnpn = async (body) => await http_client('/api/v1/persona/getPersonaRNPN', body, 'get');


export default {
    getPersonaRnpn,
}



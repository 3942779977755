<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="9" class="pa-0">
        <v-img
            src="../assets/img/footer.svg" class="mt-15">
        </v-img>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" sm="4" class="d-flex justify-center" style="gap: 20px">
        <v-btn icon @click="redirectTo('https://www.facebook.com/PresidenciaSV/?locale=es_LA')" >
          <v-img class="mx-3 " src="@/assets/img/icons/Facebook-color.svg" width="40"/>
        </v-btn>
        <v-btn icon @click="redirectTo('https://twitter.com/i/flow/login?redirect_after_login=%2FPresidenciaSV')">
          <v-img src="@/assets/img/icons/twitter-x-color.svg" width="30" class="mx-3 color-logo"/>
        </v-btn>
        <v-btn icon @click="redirectTo('https://www.youtube.com/@GobiernoSV/videos')">
          <v-img src="@/assets/img/icons/youtube-color.svg" width="30" class="mx-3"/>
        </v-btn>
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  methods: {
    redirectTo(link) {
      window.open(link, '_blank');
    },
  }
}
</script>
<style scoped>


</style>

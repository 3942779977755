// store/index.js

import Vue from 'vue'
import Vuex from 'vuex'
import utils from '../modules/utils'
import jwtDecode from 'jwt-decode'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: {},
    isAuthenticated: false
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    clearAuth(state) {
      state.token = null
      state.userInfo = {}
      state.isAuthenticated = false
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await fetch('URL_DE_TU_API_LOGIN', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        })

        if (!response.ok) {
          throw new Error('Login fallido')
        }

        const data = await response.json()
        const { token, refreshToken } = data

        commit('setUserInfo', jwtDecode(token))
        commit('setToken', token)
        localStorage.setItem('token', token)
        localStorage.setItem('refresh_token', refreshToken)
      } catch (error) {
        console.error('Error en el login:', error)
        throw error
      }
    },
    logout({ commit }) {
      commit('clearAuth')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
    },
    async checkTokenValidity({ state, dispatch }) {
      if (!state.token) {
        return false
      }

      const token = state.token
      const decodedToken = jwtDecode(token)
      const currentTime = Date.now() / 1000

      if (currentTime >= decodedToken.exp) {
        // console.log('Token expirado')
        dispatch('logout')
        return false
      }

      return true
    }
  },
  modules: {
    utils
  }
})

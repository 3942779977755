export const routerInvitaciones = [
    {
      path: "/mis-invitaciones",
      name:"mis-invitaciones",
      component: () =>
        import(
          /* webpackChunkName: "Invitacion" */ "../Invitacion.vue"
        ),
        meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },

  ];
  
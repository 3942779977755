import {http_client} from "@/plugins/http_client";
import { async } from "regenerator-runtime";

// id: idPersona
const getExemenesMedicos = async(id) => http_client(`/api/v1/persona/${id}/examenes`,{},'get')
const getDocumentos = async(id) => http_client(`/api/v1/persona/${id}/documentos`,{},'get')
const AddExamenMedico = async(data) => http_client(`/api/v1/persona/examenes`,data,'post')
const addDocumento = async(data) => http_client(`/api/v1/documento/persona`,data,'post')
const deleteDocumento = async(id) => http_client(`/api/v1/documento/persona/${id}`,{},'delete')
const deleteExamenMedico = async(id) => http_client(`/api/v1/persona/examenes/${id}`,{},'delete')
const getDocumentoPersona = async(params = {}) => http_client(`/api/v1/documento/persona/documento-tipo/${params.tipo_consulta}/${params.id_persona}/${params.tipo_documento}/cliente`,{},'get')

const getSexo = async (params) => await http_client('/api/v1/sexo',{},'get')
const getTipoSangre = async (params) => await http_client('/api/v1/tipoSangre',{},'get')
const getDatosCv = async(id) => http_client(`/api/v1/persona/${id}/cliente`,{},'get')

const getParentesco = async (params) => await http_client('/api/v1/parentesco',{},'get')
const getNivelEducativo = async (params) => await http_client('/api/v1/nivel-educativo/cliente',params,'get')
const getConocimiento = async (params = {}) => await http_client('/api/v1/conocimiento/cliente',params,'get')
const getCapacidad = async (params) => await http_client('/api/v1/capacidad/cliente',params,'get')
const getDiscapacidad = async (params) => await http_client('/api/v1/discapacidad/tipo-discapacidad',{},'get')
const getFormacionEducativa = async (body) => await http_client('/api/v1/formacion-educativa/cliente',body,'get')
const getEstadoCivil = async (params) => await http_client('/api/v1/estado-civil', {}, 'get')
const getNivelConocimiento = async (params) => await http_client('/api/v1/conocimiento/nivel-conocimiento-cliente', {}, 'get')

const actualizarPersona = async (body, id) => await http_client(`/api/v1/persona/save/${id}`,body,'put')

const finalizarRegistro = async (id_persona) => await http_client(`/api/v1/persona/finalizar-registro/${id_persona}`,{},'post')
export default {

    getDatosPersona: async (params = {}) => {
        return await http_client('/api/v1/persona/'+ params + '/cliente', {}, "get");
    },
    getExemenesMedicos,
    AddExamenMedico,
    getDocumentos,
    addDocumento,
    deleteDocumento,
    deleteExamenMedico,
    getDatosCv,
    getDocumentoPersona,
    getSexo,
    getTipoSangre,
    getParentesco,
    getNivelEducativo,
    getConocimiento,
    getCapacidad,
    getDiscapacidad,
    getFormacionEducativa,
    getEstadoCivil,
    getNivelConocimiento,
    actualizarPersona,
    finalizarRegistro,
}



<template>
  <v-app-bar app :elevation="0" color="bgWhite" dense>
    <v-btn text
      icon
      @click.stop="setSideBar(!sideBar)"
      color="white"
      v-if="!$vuetify.breakpoint.smAndDown"
    ><v-icon>mdi-menu</v-icon></v-btn>
    <v-spacer></v-spacer>

    <v-menu offset-y transition="scroll-y-transition" rounded="lg" v-if="token">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" elevation="" small>
          {{ userInfo?.user?.email }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list dense nav>
        <!-- <v-list-item :to="{name:'profile'}" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name:'security'}" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Seguridad</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="cerrarSession()" class="my-0" v-if="token">
          <v-list-item-icon>
            <v-icon size="20">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item class="my-0">
          <v-list-item-icon>
            <v-switch
                v-model="$vuetify.theme.dark"
                class="my-0"
                hint="This toggles the global state of the Vuetify theme"
                dense
            ></v-switch>
          </v-list-item-icon>
          <v-list-item-title>Tema Oscuro</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "AppHeader",
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu"]),
    ...mapMutations(["setToken"]),

     async cerrarSession() {
      if(this.isTokenValido(this.token)) {
        this.setMenu([]);
        //this.setToken(null);
        await this.services.auth.logout()
        localStorage.clear();
        this.$router.push({name: 'login'}).catch((e) => {
        });

      } else {
        this.temporalAlert({
          show:true,
          type:"error",
          message:"El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }
     },
  },
  computed: {
    ...mapState(["userInfo", "token"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
  },
};
</script>

<style scoped>
</style>
<template>
    <v-dialog v-model="internalVisible" max-width="700" persistent>
      <v-card>
        <v-card-title class="headline text-center justify-center titulo-modal pt-8 ">¡Registro con éxito!</v-card-title>
        <v-card-text class="text-center">
          <p style="color: black;" class="texto-adaptable2" >Bienvenido a nuestra bolsa de empleo. Ahora puedes disfrutar de todos los beneficios</p>
          <v-img :src="imageUrl" aspect-ratio="1.7" class="mx-auto  mb-3 pt-7 contain-img"></v-img>
        </v-card-text>
        <v-card-actions class="justify-center pb-6">
            <v-btn color="#1C1E4D" dark  @click="confirmAction" class="m-3 p-2 ">Ir a bolsa de empleo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'ConfirmationModal',
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      imageUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        internalVisible: this.visible
      };
    },
    watch: {
      visible(newValue) {
        this.internalVisible = newValue;
      },
      internalVisible(newValue) {
        this.$emit('update:visible', newValue);
      }
    },
    methods: {
      confirmAction() {
        this.internalVisible = false;
        this.$router.push({name: "login"})
      }
    }
  };
  </script>
  
  <style scoped>
  .v-card-title {
    display: flex;
    justify-content: center;
  }
  .v-card-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .v-card-actions {
    justify-content: center;
  }
  .contain-img .v-image__image {
    object-fit: contain !important;
    object-position: top !important;
  }
  @media (max-width: 600px) {
    .v-img {
      height: 150px;
    }
  }
  .titulo-modal {
  font-size: clamp(1rem, 5vw, 2.5rem) !important; /* Ejemplo de tamaño de fuente adaptable */
  color: black;
}
  </style>
  
import Vue from "vue";
import store from "../store";
import jwtDecode from "jwt-decode";

Vue.prototype.showLoader = async () => {
  await store.commit("utils/setLoader", true);
};

Vue.prototype.temporalAlert = async (alert) => {
  await store.commit("utils/setAlert", alert);
};

Vue.prototype.isDui = (dui) => {
  if (dui === null) return false;
  let valido = false;

  // Validando la longitud del numero de DUI
  if (dui.length === 10) {
    // Validando que no sea ceros todos los dígitos
    if (dui !== "00000000-0") {
      // Obteniendo los dígitos y el verificador
      let [digitos, validador] = dui.split("-");

      // Verficiando que la cadena
      if (typeof digitos !== "undefined" && typeof validador !== "undefined") {
        // Verificando que el validador sea de un solo caracter
        if (validador.length === 1) {
          // Convirtiendo los digitos a array
          digitos = digitos.split("");

          // Convirtiendo los datos a tipo integer
          validador = parseInt(validador, 10);
          digitos = digitos.map((digito) => parseInt(digito, 10));

          // Obteniendo la suma corresponiente
          let suma = digitos.reduce(
              (total, digito, index) => (total += digito * (9 - index)),
              0
          );

          // Obteniendo el Modulo base 10
          let mod = suma % 10;
          mod = validador === 0 && mod === 0 ? 10 : mod;

          let resta = 10 - mod;

          if (resta === validador) {
            valido = true;
          }
        }
      }
    }
  }
  return valido;
};

Vue.prototype.isNit = (nit) => {
  if (nit === null) return false;

  let valido = false;

  // Validando la longitud del numero de DUI
  if (nit.length === 17) {
    // Validando que no sea ceros todos los dígitos
    if (
        nit !== "0000-000000-000-0" &&
        nit.match(/^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]$/)
    ) {
      // Obteniendo los dígitos y el verificador por separado
      let [ubicacion, fecha, correlativo, validador] = nit.split("-");
      let digitos = (ubicacion + fecha + correlativo).split("");

      // Convirtiendo los datos a tipo integer
      validador = parseInt(validador, 10);
      correlativo = parseInt(correlativo, 10);
      let suma = 0;
      let mod = 0;
      if (correlativo <= 100) {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
            (total, digito, index) =>
                (total += parseInt(digito, 10) * (14 - index)),
            0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod === 10 ? 0 : mod;
      } else {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
            (total, digito, index) =>
                (total +=
                    parseInt(digito, 10) *
                    parseInt(3 + 6 * Math.floor((index + 5) / 6) - (index + 1), 10)),
            0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod > 1 ? 11 - mod : 0;
      }
      if (mod === validador) {
        valido = true;
      }
    }
  }
  return valido;
};

Vue.prototype.getPaginationProperties = (response) => {
  return {
    page: Number(response.headers.page),
    per_page: Number(response.headers.per_page),
    total_rows: Number(response.headers.total_rows)
  }
}
//Funcion auxiliar para verificar paths accesibles
const buscarRuta = (rutas, ruta) => {
  return rutas.some((item) => {
    if (item?.childrens?.length > 0)
      if (buscarRuta(item?.childrens, ruta)) return true

    return item.nombre_uri.toLowerCase() === ruta.name.toLowerCase();
  });
};

//Funcion para verificar las paths accesibles
Vue.prototype.canNext = async (ruta) => {
  //Rutas a las que el usuario siempre va a poder acceder
  const permit = [
    "Forbidden",
    "security",
    "login",
    "recuperar-password",
    "reset-password",
    "2fa",
    "verifyMail",
    "mis-postulaciones",
    "mis-postulaciones-detalle",
    "inicio",
    "expediente",
    "mi-cv",
    "empleo-detalle",
    "mis-invitaciones",
    "favoritos",
    "oferta-empleo",
    "registro",
    "editar-perfil"
  ];
  if (!ruta.name) return false;
  //Si la ruta a ingresar esta definida en el arreglo local, permite ingreso
  if (permit.some((row) => row.toLowerCase() === ruta.name.toLowerCase()))
    return true;
  //Se verifica si la ruta proporcionada se encuentra en las paths
  //almacenadas, si no hay entonces se hace la petición para almacenarlas
  let {utils} = store.state;
  if (utils.rutas && utils.rutas.length === 0) {
    await store.dispatch("utils/getMenu");
  }
  //Busca ruta en el arreglo de paths
  return buscarRuta(utils.rutas, ruta);
};

Vue.prototype.usuarioAutenticado = () => {
  return localStorage.getItem("token");
};

Vue.prototype.isTokenValido = (token) => {
  let tokenDecodificado = jwtDecode(token)
  let current_time = new Date() / 1000
  let validToken = false
  if (current_time < tokenDecodificado.exp) {
    validToken = true
  } else {
    validToken = false
  }
  return validToken
}

Vue.prototype.tieneRoles = (token) => {
  let tokenDecodificado = jwtDecode(token)
  let hayRol = false
  if (tokenDecodificado.roles !== null) {
    hayRol = true
  } else {
    hayRol = false
  }
  return hayRol
}

Vue.prototype.validateNotEmojis = (value) => {
  if (value === null) return true;
  return value.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu) !== null ? false : true;
}

Vue.prototype.typeDocNumber = {
  0: 'documento único de identidad',
  1: 'pasaporte',
  2: 'nit',
  3: 'nup',
  4: 'licencia',
  5: 'carnet de residencia',
  6: 'isss'
}

Vue.prototype.typeDocString = {
  'documento único de identidad': 'documento único de identidad',
  'passaporte':  'passaporte',
  'nit': 'nit',
  'nup': 'nup',
  'licencia': 'licencia',
  'carnet de residencia': 'carnet de residencia',
  'isss': 'isss'
}

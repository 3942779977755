// router/index.js

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routerCV from '../views/curriculum/router'
import routerExpediente from '../views/expediente/router'
import { routerMisPostulaciones } from '../views/misPostulaciones/router'
import { routerEmpleo } from '../views/empleo/router'
import { routerInvitaciones } from '../views/invitacion/Router/router'
import { routerFavoritos } from '../views/favoritos/router/index'
import { routerOFertas } from '../views/OfertaEmpleos/router/index'
import routePerfil from '../views/perfil/router'
import index from '../store/index'; // Ajusta la ruta según la estructura de tu proyecto


Vue.use(VueRouter)

const protectedRoutes = [
  ...routerMisPostulaciones,
  ...routerCV,
  ...routerExpediente,
  ...routerEmpleo,
  ...routerFavoritos,
  ...routerInvitaciones,
  ...routePerfil,
  ...routerOFertas
]

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout" */ '../views/layout'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "profiles" */ '../views/dashboard')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profiles" */ '../views/profile')
      },
      ...protectedRoutes, // Agrupamos las rutas protegidas aquí
      {
        path: '/security',
        name: 'security',
        component: () => import(/* webpackChunkName: "qr" */ '../views/auth/security')
      }
    ]
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "inicio" */ '../views/landingPage/inicio')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/login')
  },
  {
    path: '/recuperar-password',
    name: 'recuperar-password',
    component: () => import(/* webpackChunkName: "recuperarPassword" */ '../views/auth/recoverPassword')
  },
  {
    path: '/reset-password/:id',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/auth/resetPassword')
  },
  {
    path: '/verify-mail/:token',
    name: 'verifyMail',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/verifyMail')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/forbidden')
  },
  {
    path: '/autenticacionqr',
    name: '2fa',
    component: () => import(/* webpackChunkName: "qr" */ '../views/auth/2fa')
  },
  {
    path: '/registro',
    name: 'registro',
    beforeEnter: (to, from, next) => {
      const paso = localStorage.getItem('paso_formulario')
      if (paso === '0') {
        next('/oferta-empleos')
      } else {
        next()
      }
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/registro/index')
  },
  {
    path: '*',
    redirect: '/forbidden'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('initializeSession'); // Verifica y establece la sesión

  const isAuthenticated = index.state.isAuthenticated;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next(); // Continúa si está autenticado
    } else {
      next('/login'); // Redirige a login si no está autenticado y la ruta lo requiere
    }
  } else {
    next(); // Continúa a la siguiente ruta si no requiere autenticación
  }
});

export default router

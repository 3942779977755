import Vue from "vue";
import auth from './auth.services'
import path from "./path.services";
import formulario from './formulario.services'
import favoritos from "./favoritos.services";
import invitaciones from './invitaciones.services'
import OfertaEmpleo from './OfertasEmpleo.services'
import postulaciones from './postulaciones.services'
import expediente from './expediente.services'
import datosPersona from './datosPersona.services'
import examenesMedicos from "./examenesMedicos.services";
import documento from "./documentos.services";
import familiar from "./familiarPersona.services"
import formacionPersona from "./formacionPersona.services"
import capacidad from "./capacidadPersona.services"
import inicio from "./inicio.services";

Vue.prototype.services = {
    auth,
    path,
    formulario,
    favoritos,
    invitaciones,
    OfertaEmpleo,
    postulaciones,
    expediente,
    datosPersona,
    examenesMedicos,
    documento,
    familiar,
    formacionPersona,
    capacidad,
    inicio,
}
import {http_client} from "@/plugins/http_client";


const getOfertasEmpleos = async(fav_id_persona,params)=> {
    const fav_id = fav_id_persona ?? 0;
    return await http_client(`/api/v1/empleos/?fav_id_persona=${fav_id}`,params,'get');
  }

//const getOfertasEmpleos = async(fav_id_persona,params={})=> await http_client(`/api/v1/empleos/?fav_id_persona=${fav_id_persona}`,params,'get')

const getOfertasEmpleoSugeridos = async(id_persona,params={})=> await http_client(`/api/v1/empleos/sugeridos/${id_persona}`,params,'get');

const getDetalleEmpleo = async(id_oferta)=> await http_client(`/api/v1/empleos/detalle/${id_oferta}`,{},'get')

const getAreaInteres = async(body) => await http_client('/api/v1/area-de-interes',body,'get')

const getPostulacion = async(id_oferta,persona)=> await http_client(`api/v1/oferta-persona/oferta/${id_oferta}/persona/${persona}`)

const postPostularse = async(oferta, id_persona)=> await http_client(`api/v1/postulaciones/${oferta}/${id_persona}`,{},'post')

const getNivelExperiencia = async(body) => http_client('/api/v1/nivel-experiencia/',{},'get')

const getTipoHorario = async(body) => http_client('/api/v1/tipo-horarios/',{},'get')

const getPaginacionEmpleos = async(body)=> http_client(`/api/v1/empleos/${pagination=true}`,body,'get')

const getFormacionesExtras = async(id_oferta) => await http_client(`/api/v1/empleos/formaciones-extras/${id_oferta}`,{},'get')

const getConocimientosExtras = async(id_oferta) => await http_client(`/api/v1/empleos/conocimiento-extras/${id_oferta}`,{},'get')

const getFormacionAll = async(id_oferta) => await http_client(`/api/v1/empleos/formacion/${id_oferta}`,{},'get')

const getConocimientoAll = async(id_oferta) => await http_client(`/api/v1/empleos/conocimiento/${id_oferta}`,{},'get')

const getExperenciaAll= async(id_oferta) => await http_client(`/api/v1/empleos/experiencia-extras/${id_oferta}`,{},'get')

export default{
    getOfertasEmpleos,
    getDetalleEmpleo,
    getOfertasEmpleoSugeridos,
    getAreaInteres,
    getNivelExperiencia,
    getTipoHorario,
    getPostulacion,
    postPostularse,
    getPaginacionEmpleos,
    getFormacionesExtras,
    getConocimientosExtras,
    getFormacionAll,
    getConocimientoAll,
    getExperenciaAll
}

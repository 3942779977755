<template>
  <div @mouseover="hover" @mouseleave="hoverLeave">

    <v-navigation-drawer
        stateless
        permanent
        app
        :class="sideBar ? 'px-0' : 'px-2'"
        :mini-variant="this.$vuetify.breakpoint.width > 960 ? menuExpan : sideBar"
        mini-variant-width="75"
        width="295"
        @input="setSideBar($event)"
        color="#fff"
    >
      <template v-slot:prepend>
        <div elevation="2" class="ma-4 py-0" v-if="!sideBar">
          <v-btn text icon x-large color="white"
          >
            <v-icon size="30">mdi-view-headline</v-icon>
          </v-btn
          >

          <div class="d-flex justify-center align-center ml-9">
              <span class="text-h6 text-capitalize" style="color: #313945">Bolsa de empleo | {{ nombre }} {{
                  apellido
                }}</span>
          </div>
        </div>
        <v-list-item v-else two-line>
          <v-btn text icon x-large color="#313945" @click="menuExpan = !menuExpan"
          >
            <v-icon size="30">mdi-view-headline</v-icon>
          </v-btn
          >
        </v-list-item>
      </template>
      <div class="d-flex flex-column">
        <div v-if="$vuetify.breakpoint.width > 960 ? !menuExpan : menuExpan || !sideBar" class="order-0">
          <div class="text-center">
            <img src="@/assets/img/logo-secretaria.png" width="250">
          </div>
        </div>

        <div class="d-flex justify-center align-center ml-9 order-1 mt-10"
             v-if="$vuetify.breakpoint.width > 960 ? !menuExpan : menuExpan && sideBar">

          <span class="text-h6 text-capitalize" style="color: #313945">Bolsa de empleo | {{ nombre }} {{
              apellido
            }}</span>
        </div>
        <v-list dense nav v-if="menu" class="order-2">
        <span v-for="(item, i) in menu"
              :key="i">

          <v-list-group active-class="black--text" v-if="item.dependencias && item.dependencias.length">
          <template v-slot:activator>
            <v-list-item-title class="">
              {{ item.titulo }}
            </v-list-item-title>
          </template>
            <!-- <template #prependIcon>
              <v-icon color="white">{{ item.icono }}</v-icon>
            </template> -->
            <div>
              <v-list-item v-for="(child, j) in item.dependencias" :key="j" :to="child.ruta" active-class="black--text">
                <v-list-item-icon>
                  <v-icon>{{ child.icono }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="">
                  <span>{{ child.titulo }}</span>
                </v-list-item-title>
              </v-list-item>
            </div>
        </v-list-group>
          <v-list-item v-else @click="handleLinkClick(item)"
                       active-class=" black--text">
            <v-tooltip right :disabled="!sideBar" color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon v-bind="attrs" v-on="on">
                  <v-icon style="color: #313945">{{ item.icono }}</v-icon>
                </v-list-item-icon>
              </template>
              <span>{{ item.titulo }}</span>
            </v-tooltip>

          <v-list-item-title class="" style="color: #313945">
            {{ item.titulo }}
          </v-list-item-title>
          </v-list-item>
        </span>
        </v-list>
      </div>
      <!-- <footer v-if="!sideBar" class="logo-footer">
        <img src="@/assets/img/LOGOSIP.svg" alt="">
      </footer> -->

    </v-navigation-drawer>
    <ModalRegistrarmeComponent :modal="modalRegistro" @cerrar="cerrarModalRegistro()"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import ModalRegistrarmeComponent from "@/views/landingPage/components/modalRegistrarmeComponent.vue";

export default {
  name: "AppAsideBar",
  components: {ModalRegistrarmeComponent},
  props: {
    menu: {
       type: Array,
       default: () => {
         return [];
       },
    },
  },
  data: () => ({
    menuExpan: false,
    // menu: [],

    modalRegistro: false,
    nombre: null,
    apellido: null,
  }),
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    ...mapMutations(["setToken", "setUserInfo"]),
    hover() {
      if (this.$vuetify.breakpoint.width > 960) {
        this.setSideBar(true);
      } else {
        this.setSideBar(false);
      }
    },
    hoverLeave() {
      this.setSideBar(true);
    },
    handleLinkClick(item) {
    this.seguridadRuta(item.ruta);
    if (!this.modalRegistro) {
      if (this.$route.path !== item.ruta) {
        this.$router.push({ path: item.ruta }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    }
  },
    cerrarModalRegistro() {
      this.modalRegistro = false;
    },
    seguridadRuta(uri) {
      const token = localStorage.getItem("token");
      if (!token) {
        for (let i = 0; i < this.menu.length; i++) {
          const item = this.menu[i];
          if (item.ruta === uri && item.protected) {
            this.modalRegistro = true;
          }
        }
      }
    },
  },
  created() {
    this.seguridadRuta(this.$router.history.current.path);
    let p_n = this.userInfo.nombres.split(" ")
    this.nombre = p_n[0]
    let p_a = this.userInfo.apellidos.split(" ")
    this.apellido = p_a[0]
  },
  watch: {
    menuExpan(newValue) {
      this.menuExpan = newValue
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", ["sideBar"]),
  },

};
</script>

<style scoped lang="scss">
.logo-footer {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  width: 100%;
}

@media (max-width: 959px) {
  .logo-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}
</style>

export const routerFavoritos=[
    {
        path:"/favoritos",
        name:"favoritos",
        component:()=>
            import(
                /* webpackChunkName: "" */ "../Favoritos.vue"
            ),
            meta: { requiresAuth: true } // Esta ruta requiere autenticación
    }
]
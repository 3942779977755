import Vue from "vue";
import "./plugins/axios";
import "./plugins/http_client";
import "./plugins/utils";
import "./plugins/security"
import App from "./App.vue";
import router from "./router";
import './services'
import store from "./store";
import vuetify from "./plugins/vuetify";
import vMask from "v-mask";
import "./assets/scss/variables.scss";
import VueHtml2pdf from 'vue-html2pdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import InputFacade from 'vue-input-facade'
Vue.use(InputFacade)
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );

Vue.use(jsPDF);
Vue.use(html2canvas);
Vue.use(VueHtml2pdf);
const moment = require("moment");
require("moment/locale/es");
Vue.prototype.moment = moment;
import momentTime from "moment-timezone";

Vue.use(vMask);
Vue.use(require("vue-moment"), {
    moment,
    momentTime,
});

import loadComponentes from "@/plugins/loadComponents";

loadComponentes(Vue);

Vue.prototype.$currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

Vue.config.productionTip = false;

import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

new Vue({
    router,
    store,
    vuetify,
    beforeCreate() {
        const token = localStorage.getItem("token");
        if (token) store.commit("setToken", token);
    },
    render: (h) => h(App),
}).$mount("#app");

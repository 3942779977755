const routerCV = [
    {
      path: "/mi-curriculum",
      name:"mi-cv",
      component: () =>
        import(
          /* webpackChunkName: "" */ "./curriculumView.vue"
        ),
        meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },

  ];

export default routerCV;
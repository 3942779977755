
<template>
  <v-sheet class="bgMinsal" height="50">
    
      <v-row  >
        
        <v-btn 
          style="margin-top: 40px;"
          plain
          color="black" 
          fab
          absolute 
          right 
          top
          @click="drawer = !drawer">
          <v-icon size="45">mdi-view-headline</v-icon>
        </v-btn>
      
      </v-row>
    <v-navigation-drawer
      height="100%"
      v-model="drawer"
      fixed
      temporary

    >
    <template v-slot:prepend>
      <v-list-item>

        <v-list-item-content>
          <v-list-item-title class="text-wrap text-capitalize">Bolsa de empleo | {{nombre}} {{ apellido }}
          
            
          </v-list-item-title>
        
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
    </template>
    <v-list dense nav v-if="menu">
      <span v-for="(item, i) in menu"
            :key="i">

        <v-list-group active-class="white--text" v-if="item.childrens && item.childrens.length">
        <template v-slot:activator>
          <v-list-item-title class="">
            {{ item.titulo }}
          </v-list-item-title>
        </template>
      
          <div>
            <v-list-item v-for="(child, j) in item.childrens" :key="j" :to="child.uri" active-class="blueGrayMinsal--text">
              <v-list-item-icon>
                <v-icon>{{ child.icono }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="">
                <span>{{ child.titulo }}</span>
              </v-list-item-title>
            </v-list-item>
          </div>
      </v-list-group>
        <v-list-item v-else :to="item.uri"
                      active-class="blueGrayMinsal--text">
          <v-tooltip right :disabled="!sideBar" >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon >{{ item.icono }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.titulo }}</span>
          </v-tooltip>

        <v-list-item-title class="text-capitalize ">
          {{ item.titulo }}
        </v-list-item-title>
        </v-list-item>
      </span>
    </v-list>


    <template v-slot:append>
      <div class="pa-0">
        <v-list-item @click="cerrarSession()" class="my-0" v-if="token">
          <v-list-item-icon>
            <v-icon size="20">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item>
      </div>
    </template>

    </v-navigation-drawer>
  </v-sheet>
</template>



<script>
import {mapMutations, mapState} from "vuex";

export default {
name: "AppAsideBarMobil",
props: {
  menu: {
      type: Array,
      default: () => {
        return [];
      },
  },
},
data: () => ({
  
  drawer: null,
  // menu:[],
  nombre:null,
  apellido:null,
}),
methods: {
  ...mapMutations("utils", ["setSideBar", "setMenu"]),
  ...mapMutations(["setToken","setUserInfo"]),

  async cerrarSession() {
    if(this.isTokenValido(this.token)) {
      this.setMenu([]);
      //this.setToken(null);
      await this.services.auth.logout()
      localStorage.clear();
      this.$router.push({name: 'login'}).catch((e) => {
      });

    } else {
      this.temporalAlert({
        show:true,
        type:"error",
        message:"El token ya expiró, debe de volver a iniciar sesión"
      })
      this.$router.replace({name: "login"})
    }
  },
},
computed: {
  ...mapState(["userInfo", "token"]),
  ...mapState("utils", {
    sideBar: "sideBar",
  }),
},
created() {
  let p_n = this.userInfo.nombres.split(" ")
  this.nombre = p_n[0]
  let p_a = this.userInfo.apellidos.split(" ")
  this.apellido = p_a[0]
}
};
</script>

<style scoped>

.margen-abajo{
position: relative;
top: 250px;
}
</style>
import { http_client } from "@/plugins/http_client";

const getTipoFormacion = async (param) => await http_client('/api/v1/formacion/persona/tipo-formacion',param,'get')
const getFormacionPersona = async (param) => await http_client('/api/v1/formacion/persona',param,'get')
const postFormacionPersona = async (body) => await http_client('/api/v1/formacion/persona',body,'post')
const deleteFormacionPersona = async (id_formacion_persona) => await http_client(`/api/v1/formacion/persona/${id_formacion_persona}`,{},'delete')

// Conocimiento persona
const getConocimientoPersona = async (params = {}) => await http_client('/api/v1/conocimiento/persona-conocimiento', params, 'get')
const postNivelConocimiento = async (body) => await http_client('/api/v1/conocimiento/persona-conocimiento', body, 'post')
const deleteNivelConocimiento = async (id) => await http_client(`/api/v1/conocimiento/persona-conocimiento/${id}`,{},'delete')

// Experiencia persona
const getExperiencia = async (param) => await http_client('/api/v1/experiencia-laboral', param, 'get')
const postExperiencia = async (body) => await http_client('api/v1/experiencia-laboral', body, 'post')
const deleteExperiencia = async(id_experiencia) => await http_client(`/api/v1/experiencia-laboral/${id_experiencia}`,{},'delete')

export default {
    getTipoFormacion,
    getFormacionPersona,
    postFormacionPersona,
    deleteFormacionPersona,
    getConocimientoPersona,
    postNivelConocimiento,
    deleteNivelConocimiento,
    getExperiencia,
    postExperiencia,
    deleteExperiencia,
}

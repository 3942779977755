import {http_client} from "@/plugins/http_client";

const getListadoTiposExamenes = async(body) => http_client('/api/v1/examenes/tipo/',{},'get')
const getAntecedentesMedicos = async(param) => await http_client('/api/v1/antecedente-medico',param,'get')
const getTipoAntecedenteMedico = async (param) => await http_client('/api/v1/antecedente-medico/tipo-antecedentes-medico',param,'get')
const agregarAntecedenteMedico = async (body) => await http_client('/api/v1/antecedente-medico/',body,'post')
const getDiscapacidadPersona = async (param) => await http_client('/api/v1/discapacidad', param, 'get')
const agregarDiscapacidad = async (body) => await http_client('/api/v1/discapacidad', body, 'post')
const deleteDiscapacidadPersona = async (id_persona_discapacidades) => await http_client(`/api/v1/discapacidad/${id_persona_discapacidades}`,{},'delete')
const deleteAntecedenteMedico = async (id_antecedente_persona) => await http_client(`/api/v1/antecedente-medico/${id_antecedente_persona}`,{},'delete')
export default{
    getListadoTiposExamenes,
    getAntecedentesMedicos,
    getTipoAntecedenteMedico,
    agregarAntecedenteMedico,
    getDiscapacidadPersona,
    agregarDiscapacidad,
    deleteDiscapacidadPersona,
    deleteAntecedenteMedico,
}
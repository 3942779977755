<template>
  <v-dialog v-model="modalR" max-width="600px" style="border-radius: 15px;" @click:outside="cerrarM()">
    <v-card style="border-radius: 15px;">
      <v-card-title class="d-flex justify-end pb-0">
        <v-col cols="1">
          <v-btn icon @click="cerrarM()" color="#22234a"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex justify-center pt-0">
          <span class="text-color text-h4 font-weight-bold">Identifícate</span>

          <p class="pt-7 pb-4 text-center">
            Para iniciar sesión o registrarte en la plataforma, por favor, utiliza tu identidad digital para identificarte.
          </p>

          <span class="pb-0  text-h6">Registrate con Identidad Digital</span>
        </v-row>
        <v-row class="d-flex justify-center pt-0 pb-3">
          <v-btn color="#22234a" dark large class="rounded-pill"
           @click="
              goToIdentidadDigital(
                `${BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL.replace(
                  '/',
                  '%2F'
                )}`
              )">
            <img src="@/assets/img/login/login-button-l.svg" class="mr-3">
            <img src="@/assets/img/login/login-button-r.svg">
          </v-btn>
          
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      default: false
    },
  },
  data:() => ({
    modalR: false,
    REDIRECT_URL: process.env.VUE_APP_ID_REDIRECT_URL,
    BASE_URL_ID: process.env.VUE_APP_IDENTIDAD_DIGITAL_URL,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
  }),
  methods:{
    registrarme(){
        this.$router.push({name: "registro"})
    },
    cerrarM(){
        this.$emit("cerrar")
    },
    async goToIdentidadDigital(url) {
      // console.log(url);
      window.open(url, "_parent");
    },
  },

  created(){
    this.modalR = this.modal
  },
  watch:{
    modal(){
        this.modalR = this.modal
    }
  }
};
</script>

<style scoped>
.text-color {
  color: #22234a !important;
}
.bg-primary-color {
  color: #22234a;
}
 .text-secondary {
    color: #d8d8d8;
 }
</style>

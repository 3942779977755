<template>
    <div>
      <v-row class="mt-7 mx-4" >
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <span style="color: #22234a">Filas por página</span>
          <v-select
            class="mx-3"
            :items="[10, 25, 50]"
            v-model="porPagina"
            @change="updatePerPage"
            color="blue-primary"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <p style="color: #22234a">Total registros: {{ totalRegistros }}</p>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <v-pagination
            color="#22234a"
            v-model="pagina"
            @input="cambiarPagina"
            :length="totalPages || 0"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: "AppPagination",
    props: {
      page: {
        type: Number,
        default: 1,
      },
      totalRegistros: {
        type: Number,
        default: 0,
      },
      perPage: {
        type: Number,
        default: 25,
      },
    },
  
    methods: {
      cambiarPagina(event) {
        this.$emit("input", event);
      },
      updatePerPage() {
        this.pagina = 1;
        this.$emit("change");
      },
    },
    computed: {
      pagina: {
        get() {
          return this.page;
        },
        set(value) {
          this.$emit("update:page", value);
        },
      },
      porPagina: {
        get() {
          return this.perPage;
        },
        set(value) {
          this.$emit("update:perPage", value);
        },
      },
      totalPages() {
        return Math.ceil(this.totalRegistros / this.perPage);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .v-select {
    max-width: 55px !important;
  }
  
  :deep(.v-select__selection--comma) {
    margin: 7px 4px 7px 0;
    min-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #22234a !important;
  }
  
  :deep(.theme--light.v-pagination .v-pagination__item--active ){
      color: #FFFFFF;
      background-color: #22234a;
  }
  </style>
  
import {http_client} from "@/plugins/http_client";

const getListadoBancos = async(body) => http_client('/api/v1/acreedor/',{},'get')
const putRegistroBanco = async(body) => http_client(`/api/v1/persona/${body.id_persona}/agregar_cuenta`,body.data,'put')
const getDatoBancario = async(body) => http_client(`/api/v1/persona/${body}/dato_bancario`,{},'get')

export default{
    getListadoBancos,
    putRegistroBanco,
    getDatoBancario,
}